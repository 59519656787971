
import Vue from 'vue'
import { expandMenuItem } from '../store/actions'
import { IMenuItem } from '../store/state'

// Interfaces for component typechecking!
interface MenuItemData {

}

interface MenuItemMethods {
  expandMenuItem(item: IMenuItem): void
  checkExpanded(): boolean
}

interface MenuItemComputed {
	currentLevel: number
	isMobile: boolean
  currentPageId: string
}

interface MenuItemProps {
	item: IMenuItem
}

export default Vue.extend<MenuItemData, MenuItemMethods, MenuItemComputed, MenuItemProps>({
	name: 'MenuItem',
	props: {
		item: {}
	},
	computed: {
    currentLevel(){
      return this.$store.getters.menuBreadCrumbs.length
    },
    isMobile(){
      return this.$store.state.isMobile
    },
    currentPageId(){
      return this.$store.state.currentPageId
    },
	},
	methods: {
		expandMenuItem(item) {
			this.$store.dispatch(expandMenuItem(item))
		},
    checkExpanded() {
      if(this.item.isExpanded) return this.currentLevel <= this.item.level
      else if(!this.item.isExpanded) return this.currentLevel < this.item.level
      else return false
    }
	},
	components: {
	}
})
