import { MutationTree, Mutation } from 'vuex';
import { IState, IMenuItem } from './state';
import { ActionPayload } from './actions';

interface IAddMenuChildrenPayload {
	menuItem: IMenuItem,
	children: IMenuItem[]
}

export interface IToggleMenuItem {
	menuItem?: IMenuItem
	showMenu: boolean
}

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const setMenuMutation: MutationHandler<IMenuItem[]> = (state, {payload}) => {
	Object.assign(state, {menu: payload})
}

const toggleMenuMutation: MutationHandler<IToggleMenuItem> = (state, {payload}) => {
	if(payload.menuItem) {
		Object.assign(payload.menuItem, {isExpanded: payload.showMenu})
	}
	Object.assign(state, {showMenu: payload.showMenu})
}

const addChildrenMutation: MutationHandler<IAddMenuChildrenPayload> = (state, { payload }) => {
	if(payload.menuItem) {
		Object.assign(payload.menuItem, { children: payload.children})
	}
}

const expandMenuItemMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(payload, {isExpanded: !payload.isExpanded, isCurrent: !payload.isCurrent})
	state.menuBreadCrumbs.push(payload)
}

const unexpandMenuItemMutation: MutationHandler<undefined> = (state, { payload }) => {
	const lastItem = state.menuBreadCrumbs.length - 1;
	Object.assign(state.menuBreadCrumbs[lastItem], {isExpanded: !state.menuBreadCrumbs[lastItem].isExpanded})
	state.menuBreadCrumbs.pop()
}

const unexpandMainMenuItemsMutation: MutationHandler<undefined> = (state, { payload }) => {
	state.menu.forEach(menuItem => {
		Object.assign(menuItem, {isExpanded: false})
	})
}

const setMobileMutation: MutationHandler<boolean> = (state, { payload }) => {
	Object.assign(state, {isMobile: payload})
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	setMobile: setMobileMutation,
	setMenu: setMenuMutation,
	addChildren: addChildrenMutation,
	toggleMenu: toggleMenuMutation,
	expandMenuItem: expandMenuItemMutation,
	unexpandMenuItem: unexpandMenuItemMutation,
	unexpandMainMenuItems: unexpandMainMenuItemsMutation
}

export default mutations
