
import Vue from 'vue'
import { toggleMenu } from '../store/actions'

interface MobileMenuButtonMethods {
  toggleMenu(): void
}

interface MobileMenuButtonComputed {
  showMenu: boolean
}

export default Vue.extend<{}, MobileMenuButtonMethods, MobileMenuButtonComputed, {}>({
	name: 'MobileMenuButton',
  computed: {
    showMenu() {
      return this.$store.state.showMenu
    },
  },
  methods: {
    toggleMenu() {
      this.$store.dispatch(toggleMenu({
        showMenu: !this.showMenu
      }))

      setTimeout(() => {
        const html = document.documentElement;
        if(!this.showMenu) {
          html.removeAttribute('menu-open');
        } else {
          html.setAttribute('menu-open', '');
        }
      }, 250)
    }
  },
  components: {
  }
})
