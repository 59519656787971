import { IState, IMenuItem } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'getMenu' | 'getChildren' | 'toggleMenu' | 'expandMenuItem'

export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

export interface IGetChildrenPayload {
	id: string
	level: number
}

export interface IToggleMenuItem {
	menuItem?: IMenuItem
	showMenu: boolean
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

export const getMenu: ActionCreator<undefined> = payload => ({
	type: 'getMenu',
	payload: payload
})

export const getChildren: ActionCreator<IGetChildrenPayload> = payload => ({
	type: 'getChildren',
	payload: payload
})

export const toggleMenu: ActionCreator<IToggleMenuItem> = (payload) => ({
	type: 'toggleMenu',
	payload: payload
})

export const expandMenuItem: ActionCreator<IMenuItem> = (payload) => ({
	type: 'expandMenuItem',
	payload: payload
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit, dispatch }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})

	const width = Math.max(
		document.body.scrollWidth,
		document.documentElement.scrollWidth,
		document.body.offsetWidth,
		document.documentElement.offsetWidth,
		document.documentElement.clientWidth,
		window.innerWidth
	);
	commit({
		type: 'setMobile',
		payload: width <= 768
	})
	dispatch(getMenu(undefined))
}

const getMenuAction: ActionHandler<undefined> = ({ commit, state }, { payload }) => {
	api.getMenu(state.endpoints.getMenu, {isDesktop: !state.isMobile})
		.then(res => {
			commit({
				type: 'setMenu',
				payload: res
			})
		})
}

const getChildrenAction: ActionHandler<IMenuItem> = ({ commit, state }, { payload }) => {
	api.getMenuChildren(state.endpoints.getChildren, {id: payload.id, level: payload.level})
		.then(res => {
			commit({
				type: 'addChildren',
				payload: {menuItem: payload, children: res}
			})
		})
}

const toggleMenuAction: ActionHandler<IToggleMenuItem> = ({ commit, dispatch, state }, { payload }) => {
	if(!payload.showMenu) {
		console.log('getmenu', !payload.showMenu)
		console.log('mobile', state.isMobile)
		console.log('bc', state.menuBreadCrumbs)
		console.log(JSON.parse(JSON.stringify(state.menu)))
		dispatch(getMenu(undefined))
		console.log(JSON.parse(JSON.stringify(state.menu)))
	}

	if(payload.menuItem && payload.menuItem.children.length < 1) {
		dispatch(getChildren(payload.menuItem))
	}
	
	commit({
		type: 'toggleMenu',
		payload: payload
	})
}

const expandMenuItemAction: ActionHandler<IMenuItem> = ({ commit, dispatch }, { payload }) => {
	if(payload.children.length < 1) {
		dispatch(getChildren(payload))
	}

	commit({
		type: 'expandMenuItem',
		payload: payload
	})
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	getMenu: getMenuAction,
	getChildren: getChildrenAction,
	toggleMenu: toggleMenuAction,
	expandMenuItem: expandMenuItemAction
}

export default actions;
