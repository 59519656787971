
import Vue from 'vue'
import MobileMenuButton from './components/MobileMenuButton.vue'
import Logotype from './components/Logotype.vue'
import Menu from './components/Menu.vue'
import MenuButtons from './components/MenuButtons.vue'
import OriginLink from './components/OriginLink.vue'
import MobileMenu from './components/MobileMenu.vue'

// Interfaces for component typechecking!
interface AppData {
	height: number
}

interface AppMethods {

}

interface AppComputed {
	showMenu: boolean
	isMobile: boolean
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	data() {
		return {
			height: 0
		}
	},
	computed: {
    showMenu() {
      return this.$store.state.showMenu
    },
		isMobile() {
			return this.$store.state.isMobile
		}
	},
	created() {
		document.addEventListener('DOMContentLoaded', () => {
			this.height = window.innerHeight - (this.$refs.header as HTMLElement).clientHeight;
		})
		addEventListener('resize', () => {
			const width = Math.max(
				document.body.scrollWidth,
				document.documentElement.scrollWidth,
				document.body.offsetWidth,
				document.documentElement.offsetWidth,
				document.documentElement.clientWidth,
				window.innerWidth
			);
			this.$store.commit({
				type: 'setMobile',
				payload: width <= 768
			})

			this.height = window.innerHeight - (this.$refs.header as HTMLElement).clientHeight;
		})
	},
	components: {
		MenuButtons,
		Menu,
		Logotype,
		OriginLink,
		MobileMenuButton,
		MobileMenu
	}
})
