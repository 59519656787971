
import Vue from 'vue'
import { ILogotype, IStartPage } from '../store/state'

// Interfaces for component typechecking!
interface LogotypeData {

}

interface LogotypeMethods {

}

interface LogotypeComputed {
	logotype: ILogotype
	startPage: IStartPage
}

interface LogotypeProps {

}

export default Vue.extend<LogotypeData, LogotypeMethods, LogotypeComputed, LogotypeProps>({
	name: 'Logotype',
	computed: {
    logotype() {
      return this.$store.state.logotype
    },
    startPage() {
      return this.$store.state.startPage
    }
	},
	components: {
	}
})
