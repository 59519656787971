export interface IState {
	logotype: ILogotype
	startPage: IStartPage
	origin: IOriginLink
	menu: IMenuItem[]
	menuBreadCrumbs: IMenuItem[]
	currentPageId: string
	endpoints: {
		getMenu: string
		getChildren: string
	}
	isMobile: boolean
	showMenu: boolean
}

export interface IOriginLink {
	originUri: string
	imageUri: string
}

export interface ILogotype {
	uri: string
	altText: string
}

export interface IStartPage {
	uri: string
	isStartPage: boolean
}

export interface IMenuItem {
	name: string
	uri: string
	id: string
	isCurrent: boolean
	isExpanded: boolean
	hasChildren: boolean
	level: number
	children: IMenuItem[]
}

const defaultState: IState = {
	logotype: {
		uri: '',
		altText: ''
	},
	startPage: {
		uri: '',
		isStartPage: true
	},
	origin: {
		originUri: '',
		imageUri: ''
	},
	menu: [],
	menuBreadCrumbs: [],
	endpoints: {
		getMenu: '',
		getChildren: ''
	},
	isMobile: false,
	showMenu: false,
	currentPageId: ''
}

export default defaultState;
