import { Getter } from "vuex";
import { IMenuItem, IState } from './state';
const MAX_ITERATIONS = 1000;

const checkExpanded = (children?: IMenuItem[], list?: IMenuItem[], iteration?: number) => {
	if(iteration && iteration > MAX_ITERATIONS || !children) return
	const breadCrumbs : IMenuItem[] = list ? list : []
	let i = iteration ? iteration : 0;

	children.some((child: IMenuItem) => {
		if (child.isExpanded) {
			breadCrumbs.push(child)
			if (child.hasChildren) checkExpanded(child.children, breadCrumbs, ++i)
			return true;
		}
	})
	return breadCrumbs
}

const menuBreadCrumbs: Getter<IState, IState> = (state, getters) => {
	const breadcrumbs = checkExpanded(state.menu);
	if(breadcrumbs) state.menuBreadCrumbs = breadcrumbs
	return state.menuBreadCrumbs
};

const activeMenuItem: Getter<IState, IState> = (state, getters) => {
	return state.menu.find(menuItem => menuItem.isExpanded);
}

export default {
	menuBreadCrumbs,
	activeMenuItem
}
