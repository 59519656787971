
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import MenuItem from './MenuItem.vue';

interface MenuMethods {
  unexpandMenuItem(): void
}

interface MenuComputed {
	menu: IMenuItem
  currentLevel: number
}

interface MenuProps {
  height: number
}

export default Vue.extend<{}, MenuMethods, MenuComputed, MenuProps>({
	name: 'Menu',
  props: {
    height: () => 0
  },
  computed: {
    menu() {
      return this.$store.state.menu
    },
    currentLevel(){
      return this.$store.getters.menuBreadCrumbs.length
    },
  },
  methods: {
    unexpandMenuItem() {
      this.$store.commit({
        type: 'unexpandMenuItem',
        payload: undefined
      })
    }
  },
  components: {
    MenuItem
  }
})
