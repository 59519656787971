
import Vue from 'vue'
import { IOriginLink } from '../store/state'

// Interfaces for component typechecking!
interface OriginLinkData {

}

interface OriginLinkMethods {

}

interface OriginLinkComputed {
	originLink: IOriginLink
}

interface OriginLinkProps {

}

export default Vue.extend<OriginLinkData, OriginLinkMethods, OriginLinkComputed, OriginLinkProps>({
	name: 'OriginLink',
	computed: {
    originLink() {
      return this.$store.state.originLink
    }
	},
	components: {
	}
})
