
import Vue from 'vue'
import MenuItem from './MenuItem.vue'
import { IMenuItem } from '../store/state'
import { toggleMenu } from '../store/actions'

// Interfaces for component typechecking!
interface MenuData {

}

interface MenuMethods {
  closeMenu(): void
  unexpandMenuItem(): void
}

interface MenuComputed {
  activeMenuItem: IMenuItem
  currentLevel: number
  showMenu: boolean
  isMobile: boolean
}

interface MenuProps {

}

export default Vue.extend<MenuData, MenuMethods, MenuComputed, MenuProps>({
	name: 'Menu',
	computed: {
    activeMenuItem() {
      return this.$store.getters.activeMenuItem
    },
    currentLevel(){
      return this.$store.getters.menuBreadCrumbs.length
    },
    showMenu(){
      return this.$store.state.showMenu
    },
    isMobile(){
      return this.$store.state.isMobile
    }
	},
  methods: {
    closeMenu() {
      this.$store.dispatch(toggleMenu({
        showMenu: false
      }))
      this.$store.commit({
        type: 'unexpandMainMenuItems'
      })

      const html = document.documentElement;
      if(!this.showMenu) {
        html.removeAttribute('menu-open');
      } else {
        html.setAttribute('menu-open', '');
      }
      
    },
    unexpandMenuItem() {
      this.$store.commit({
        type: 'unexpandMenuItem',
        payload: undefined
      })
    }
  },
  mounted() {
    !this.isMobile && window.addEventListener('click', (e) => {
      const appRef = this.$refs.menu as HTMLElement;
      const target = e.target as HTMLElement;
      if((appRef?.contains(target)) || target.dataset?.ref === 'menu-button' || target.hasAttribute('data-ignore-close')) return;
      if(this.showMenu) this.closeMenu();
    })
  },
	components: {
    MenuItem
	}
})
