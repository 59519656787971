
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { toggleMenu } from '../store/actions'
// Interfaces for component typechecking!
interface MenuButtonsData {

}

interface MenuButtonsMethods {
  openMenu(menuItem: IMenuItem): void
}

interface MenuButtonsComputed {
	menu: IMenuItem[]
}

interface MenuButtonsProps {

}

export default Vue.extend<MenuButtonsData, MenuButtonsMethods, MenuButtonsComputed, MenuButtonsProps>({
	name: 'MenuButtons',
	computed: {
		menu() {
			return this.$store.state.menu
		}
	},
	methods: {
		openMenu(menuItem) {
      this.$store.dispatch(toggleMenu({
        menuItem: menuItem,
        showMenu: true
      }))
      const html = document.documentElement;
      html.setAttribute('menu-open', '');
    }
	},
	components: {
	}
})
